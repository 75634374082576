import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './components/DateRangePickerWrapper/react_dates_overrides.css'
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import LightTheme from "./themes/light-theme";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import React from "react";
import ReactDOM from "react-dom";
import { LocalizationProvider } from "@material-ui/pickers";
import { StylesProvider, MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistor, { store } from "./store";
import "./utils/rollbar";

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={LightTheme}>
        <MuiThemeProvider theme={LightTheme}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </Provider>
          </LocalizationProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
