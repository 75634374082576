import React, { useState } from "react";
import Input from "../../../../../../Input/index";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

type Props = {
  value: any;
  isValid?: any;
  onChange: any;
  label: string;
  id: string;
  type: string;
};

const PasswordInput: React.FC<Props> = ({
  onChange,
  label,
  id,
  type,
  isValid,
  value,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [valid, setValid] = useState(undefined);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);

    if (value !== "") {
      const result = isValid(value);
      setValid(result);
    } else {
      setValid(undefined);
    }
  };

  return (
    <Input
      id={id}
      label={label}
      onChange={handleChange}
      fullWidth
      required
      type={showPassword ? "text" : type}
      value={value}
      valid={valid}
      error={valid === false}
      variant="filled"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" variant="standard">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <Visibility style={{ fontSize: 16 }} />
              ) : (
                <VisibilityOff style={{ fontSize: 16 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
