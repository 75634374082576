import { Box } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import Form from "./Form";

const StyledBox = styled(Box)<{ open: boolean }>`
  position: absolute;
  width: 400px;
  right: -400px;
  display: flex;
  z-index: 999;
  transition: right 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  top: 0;

  > div {
    visibility: hidden;
    transition: all 0s ease-in-out 0.6s;
    display: flex;
    justify-content: center;
  }

  ${({ open }) =>
    open &&
    css`
      right: 0;

      > div {
        visibility: visible;
        transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
      }
    `}
`;

const Filters: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <StyledBox open={open}>
      <Button onClick={() => setOpen(!open)} />
      <Form closeForm={() => setOpen(false)} />
    </StyledBox>
  );
};

export default Filters;
