import React from "react";
import styled from "styled-components";
import { TableView } from "../../types/Table";

type Props = {
  off: TableView;
  on: TableView;
  text?: string;
  view: TableView;
  handleOnChange: (value: TableView) => void;
};

interface OptionProps extends Pick<Props, "off" | "on"> {
  handleChange: (isActive: boolean) => void;
  isActive: boolean;
  type: "on" | "off";
}

const TextSwitchWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  div {
    color: ${(props) => props.theme.palette.primary.main};
  }

  div:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const StyledOption = styled.div`
  cursor: pointer;

  &.active {
    font-weight: bold;
  }
`;

const Option = ({ handleChange, isActive, type, off, on }: OptionProps) => (
  <StyledOption
    className={isActive ? "active" : ""}
    onClick={() => handleChange(type === "on" ? true : false)}
  >
    {type === "on" ? on : off}
  </StyledOption>
);

const TextSwitch: React.FC<Props> = ({
  on,
  off,
  text,
  view,
  handleOnChange,
}) => {
  const handleChange = (value: boolean) => {
    if (handleOnChange) {
      handleOnChange(value === true ? on : off);
    }
  };

  const OptionProps = { on, off, handleChange };

  return (
    <TextSwitchWrapper>
      {text && `${text}:`}
      <Option type={"on"} isActive={view === on} {...OptionProps} />
      <Option type={"off"} isActive={view === off} {...OptionProps} />
    </TextSwitchWrapper>
  );
};

export default TextSwitch;
