import priceFinder from "../../utils/priceFinder";
import rowFormatter from "../../utils/rowFormatter";
import nest, { Nest } from "../../utils/nest";
import { Cell, DataItem, Row } from "../../types/Table";
import isFirst from "../utils/isFirst";
import isOdd from "../utils/isOdd";
import isLast from "../utils/isLast";
import getMinPrice from "../utils/getMinPrice";

const groupData = (data: DataItem[]) =>
  nest(data, ["productName", "retailer", "_day"]);

const reduceDayEntries = (
  retailerEntries: [string, DataItem[] | Nest<DataItem>][],
  getPrice: (item: DataItem[]) => string
) => {
  const reducedDayEntries = retailerEntries.map(([productName, products]): [
    string,
    DataItem[]
  ] => {
    const productsValues: DataItem[][] = Object.values(products);
    const reducedProducts = productsValues.reduce((acc, current) => {
      const price = getPrice(current);
      const newRecord: DataItem = { ...current[0], price };
      return [...acc, newRecord];
    }, []);

    return [productName, reducedProducts];
  });

  return reducedDayEntries;
};

const getRows = (
  reducedDayEntries: [string, DataItem[]][],
  dates: Cell[],
  productIndex: number,
  retailerEntries: any[]
) => {
  const result: Row[] = [];

  reducedDayEntries.forEach(([, retailerProducts], retailerIndex) => {
    const pricesFound = priceFinder(retailerProducts, dates);

    const row = rowFormatter({
      products: retailerProducts,
      datePrices: pricesFound,
      isFirst: isFirst(retailerIndex),
      isOdd: isOdd(productIndex),
      isLast: isLast(retailerIndex, retailerEntries),
      index: retailerIndex,
    });

    result.push(row);
  });

  return result;
};

const process = (data: DataItem[], dates: Cell[]): Row[] => {
  const groupedData = groupData(data);

  const result: Row[] = [];

  const productEntries = Object.entries(groupedData);

  productEntries.forEach(([, products], productIndex: number) => {
    const retailerEntries = Object.entries(products as Nest<DataItem>);
    const reducedDayEntries = reduceDayEntries(retailerEntries, getMinPrice);

    const rows = getRows(
      reducedDayEntries,
      dates,
      productIndex,
      retailerEntries
    );

    result.push(...rows);
  });

  return result;
};

export default process;
