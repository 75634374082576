import React, { useState } from "react";
import { Box, FormControlLabel, Checkbox } from "@material-ui/core";
import TextButtonComponent from "../../../TextButton/index";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledBox = styled(Box)`
  margin-top: 16px;
`;

type Props = {};

const ActionsBar: React.FC<Props> = () => {
  const [rememberUser, setRememberUser] = useState<boolean>(true);

  return (
    <StyledBox display="flex" justifyContent="space-between">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={rememberUser}
            name="rememberUser"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRememberUser(Boolean(e.target.checked));
            }}
          />
        }
        label="Remember me"
      />
      <TextButtonComponent>
        <Link
          to="/password-reset"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          Forgot password?
        </Link>
      </TextButtonComponent>
    </StyledBox>
  );
};

export default ActionsBar;
