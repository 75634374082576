import React from "react";
import webAuth from "./utils/auth0";
import { setIdToken, getCubeJsToken } from "./reducers/auth";
import { useDispatch } from "react-redux";
import useLocation from "./hooks/useLocation";
import Routes from "./components/Routes";

function App() {
  const dispatch = useDispatch();
  const hash = useLocation("hash");

  React.useEffect(() => {
    if (hash) {
      webAuth.parseHash({ hash }, function (err, authResult) {
        if (err) {
          return console.log(err);
        }

        if (authResult && authResult.idToken) {
          dispatch(setIdToken(authResult.idToken));
          dispatch(getCubeJsToken());
        }
      });
    }
  }, [dispatch, hash]);

  React.useEffect(() => {
    (window as any).location = 'https://app.modvise.com/'
  }, []);

  return <Routes />;
}

export default App;
