import _ from "lodash";
import addParsedDate from "../utils/addParsedDate";
import {responseKeyMap} from "./keyMap";
import { VariantFull } from "../types/VariantFull";

const replaceKeys = (obj: any, keyMap: any) => {
  return _.mapKeys(obj, (value, key) => keyMap[key]);
};

const replaceNullPrices = ({ price, ...rest }: any) => ({
  ...rest,
  price: price !== null ? price : "0.00",
});

const formatPrice = ({ price, ...rest }: any) => ({
  ...rest,
  price: Number(price).toFixed(2),
});

const filterNullDates = ({ rundate }: any) => !!rundate;

export const getFormatedList = (tablePivot: VariantFull[]) => {
  const result = _.chain(tablePivot)
    .map((obj) => replaceKeys(obj, responseKeyMap))
    .map(replaceNullPrices)
    .map(formatPrice)
    .filter(filterNullDates)
    .map(addParsedDate)
    .sortBy(["productName", "retailer"])
    .value();

  return result;
};

const processData = (tablePivot: VariantFull[]) => {
  const result = getFormatedList(tablePivot as VariantFull[]);

  return result;
};

export default processData;
