import getModelName from "../utils/getModelName";

const Variants = getModelName("Variants");

const keyMap = {
  [`${Variants}.url`]: "url",
  [`${Variants}.price`]: "price",
  [`${Variants}.available`]: "available",
  [`${Variants}.productname`]: "productName",
  [`${Variants}.retailername`]: "retailer",
  [`${Variants}.rundate`]: "rundate",
};

export const responseKeyMap = {
  ...keyMap,
  [`${Variants}.createdat.day`]: "createdAt",
};

export default keyMap;
