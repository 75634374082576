import cubejsApi from "./index";
import keyMap from "../../processing/keyMap";
import { Filter } from "@cubejs-client/core";
import getModelName from "../getModelName";

const filters: Filter[] = [
  // {
  //   dimension: "Variants_prod.retailername",
  //   operator: "contains",
  //   values: ["Media"],
  // },
  // {
  //   dimension: "Variants_prod.productname",
  //   operator: "contains",
  //   values: ["32LK510B"],
  // },
];

export const getClientVariants = async (
  accessToken: string,
  dateRange: string[],
  offset: number,
  limit: number,
) => {
  const Variants = getModelName("Variants");

  const resultSet = await cubejsApi(accessToken).load({
    dimensions: Object.keys(keyMap),
    timeDimensions: [
      {
        dimension: `${Variants}.createdat`,
        granularity: "day",
        dateRange,
      },
    ],
    filters,
    order: { // should be specific to avoid duplicates
      [`${Variants}.rundate`]: "desc",
      [`${Variants}.retailername`]: "desc",
      [`${Variants}.productname`]: "desc",
    },
    limit,
    offset,
  });

  const tablePivot = resultSet.tablePivot().filter(Boolean);

  return tablePivot;
};

export const measureClientVariants = async (
  accessToken: string,
  dateRange: string[]
) => {
  const Variants = getModelName("Variants");

  const data = await cubejsApi(accessToken).load({
    measures: [`${Variants}.count`],
    filters,
    timeDimensions: [
      {
        dimension: `${Variants}.createdat`,
        granularity: "day",
        dateRange,
      },
    ],
  });

  const sum = data
    .tablePivot()
    .map((day) => Number(day[`${Variants}.count`]))
    .reduce((acc, prev) => acc + prev, 0);

  return sum;
};
