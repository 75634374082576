import EmailInput from "./Email";
import PasswordInput from "./Password";
import validateEmail from "../../../../../../utils/validateEmail";
import { AppDispatch } from "../../../../../../store";
import { RootState } from "../../../../../../reducers/rootReducer";
import {
  setEmail,
  setPassword,
  setNewPassword,
  setConfirmPassword,
} from "../../../../../../reducers/auth";

const Fields = {
  Email: {
    name: "Email",
    key: "Email",
    type: "email",
    component: EmailInput,
    getValue: (state: RootState) => state.auth.email,
    setValue: (dispatch: AppDispatch, value: string | number) =>
      dispatch(setEmail(value)),
    isValid: (value: any) => validateEmail(value),
  },
  Password: {
    name: "Password",
    key: "Password",
    type: "password",
    component: PasswordInput,
    getValue: (state: RootState) => state.auth.password,
    setValue: (dispatch: AppDispatch, value: string | number) =>
      dispatch(setPassword(value)),
    isValid: (value: any) => value.length >= 6,
  },
  NewPassword: {
    name: "New password",
    key: "NewPassword",
    type: "password",
    component: PasswordInput,
    getValue: (state: RootState) => state.auth.newPassword,
    setValue: (dispatch: AppDispatch, value: string | number) =>
      dispatch(setNewPassword(value)),
    isValid: (value: any) => value.length >= 6,
  },
  ConfirmPassword: {
    name: "Confirm password",
    key: "ConfirmPassword",
    type: "password",
    component: PasswordInput,
    getValue: (state: RootState) => state.auth.confirmPassword,
    setValue: (dispatch: AppDispatch, value: string | number) =>
      dispatch(setConfirmPassword(value)),
    isValid: (value: any) => value.length >= 6, // TODO: the same length as new password
  },
};

export default Fields;
