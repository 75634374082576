import React, { useState, useEffect } from "react";
import { FullWidthLinearLoader } from "../../../../../../LinearLoader/index";
import Table from "../../index.jsx";
import styled from "styled-components";
import { getList } from "../../../../../../../processing/index";
import { RootState } from "../../../../../../../reducers/rootReducer";
import { useSelector } from "react-redux";

const StyledWrapper = styled.div`
  height: calc(100vh - 163px);
  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    #f6f7f8 25%,
    #f6f7f8 50%,
    #ffffff 50%,
    #ffffff 75%,
    #f6f7f8 75%,
    #f6f7f8 100%
  );
  background-size: 2.83px 2.83px;

  .TopRightGrid_ScrollWrapper {
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background: #bdbdbd;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .ReactVirtualized__Grid {
      background: white !important;
    }
  }

  .ReactVirtualized__Grid {
    &:focus {
      outline: none;
    }
  }
`;

type Props = {
  handleOpenModal: () => void;
};

const Wrapper: React.FC<Props> = ({ handleOpenModal }) => {
  const {
    tableHead,
    view,
    variants,
    isTableLoading,
    isRunsLoading,
  } = useSelector((state: RootState) => state.productAnalysis);
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    const newList = getList(variants, { view, tableHead });

    setList(newList);
  }, [isTableLoading, isRunsLoading, variants, view, tableHead]);

  if (list.length <= 2) {
    return (
      <FullWidthLinearLoader width={250} text="Building your data table" />
    );
  }

  // if (error) {
  //   return <div>{error.toString()}</div>;
  // }

  if (!variants) {
    return null;
  }

  return (
    <StyledWrapper>
      <Table list={list} handleOpenModal={handleOpenModal} />
    </StyledWrapper>
  );
};

export default Wrapper;
