import React from "react";
import styled, { css } from "styled-components";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

type Props = {
  valid?: boolean;
  error?: boolean;
} & TextFieldProps

const StyledTextField = styled.div<Props>`
  ${(props) =>
    props.valid &&
    css`
      label {
        color: #28a745;
      }

      label + div:before {
        color: #28a745;
        border-bottom-width: 2px;
      }
    `};

  ${(props) =>
    props.error &&
    css`
      label {
        color: #f00f00;
      }

      label + div:before {
        color: #f00f00;
        border-bottom-width: 2px;
      }
    `};
`;

const Input: React.FC<Props> = ({ error, valid, ...props }) => (
  <StyledTextField error={error} valid={valid}>
    <TextField {...props} />
  </StyledTextField>
);

export default Input;
