import { Box } from "@material-ui/core";
import styled from "styled-components";

const StyledBox = styled(Box)`
  position: relative;
  min-height: 100vh;
  overflow: hidden !important;
  background: ${(props) => props.theme.components.login.background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledBox;
