import React, { useState } from "react";
import Input from "../../../../../../Input";

type Props = {
  value: any;
  isValid?: any;
  onChange: any;
  label: string;
  id: string;
  type: string;
};

const EmailInput: React.FC<Props> = ({
  onChange,
  label,
  id,
  type,
  isValid,
  value,
}) => {
  const [valid, setValid] = useState(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);

    if (value) {
      const result = isValid(value);
      setValid(result);
    } else {
      setValid(undefined);
    }
  };

  return (
    <Input
      valid={valid}
      error={valid === false}
      id={id.toLowerCase()}
      label={label}
      type={type}
      variant="filled"
      fullWidth
      autoFocus
      inputProps={{ maxLength: 64 }}
      required
      value={value}
      onChange={handleChange}
    />
  );
};

export default EmailInput;
