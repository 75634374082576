import { combineReducers } from "@reduxjs/toolkit";
import productAnalysis from "../reducers/productAnalysis";
import auth from "../reducers/auth";

const rootReducer = combineReducers({
  productAnalysis,
  auth
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
