import React from "react";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

type Props = {
  width: number;
  text: string;
  [key: string]: any;
};

const FullWidthLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
`;

const LinearLoaderWrapper = styled.div<Props>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.palette.text.extraLight};

  p {
    margin-top: 28px;
    text-align: center;
  }

  ${({ width }) => width && `width: ${width}px;`}
`;

const Progress = styled(LinearProgress)``;

const LinearLoader: React.FC<Props> = ({ text, width, ...props }) => (
  <LinearLoaderWrapper width={width} {...props}>
    <Progress />
    <p>{text}</p>
  </LinearLoaderWrapper>
);

export const FullWidthLinearLoader: React.FC<Props>  = (props) => (
  <FullWidthLoaderWrapper>
    <LinearLoader {...props} />
  </FullWidthLoaderWrapper>
);

export default LinearLoader;
