import React from "react";
import styled, { css } from "styled-components";
import { Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";

type Props = {
  bigTextChildren: string;
  smallTextChildren: string;
  errorText?: string;
};

const StyledBox = styled(Box)`
  margin-bottom: 55px;
  color: ${(props) => props.theme.palette.grey.dark};
  text-align: center;
`;

const StyledBigText = styled(Typography)`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
`;

const StyledSmallText = styled(Typography)<{ $errorText?: string }>`
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 10px;

  ${({ $errorText, theme }) =>
    Boolean($errorText) &&
    css`
      color: ${theme.components.login.leftSideBox.text.red};
    `}
`;

const Header: React.FC<Props> = ({
  bigTextChildren,
  smallTextChildren,
}) => {
  const { error } = useSelector((state: RootState) => state.auth);
  return (
    <StyledBox>
      <StyledBigText variant="body1">{bigTextChildren}</StyledBigText>
      <StyledSmallText $errorText={error} variant="body1">
        {error ? error : smallTextChildren}
      </StyledSmallText>
    </StyledBox>
  );
};

export default Header;
