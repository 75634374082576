import React, { useEffect } from "react";
import BackgroundElements from "./components/Backgrond";
import Blog from "./components/Blog/index";
import LeftSideBox from "./components/LeftSideBox/index";
import Logo from "../Logo/index";
import RightSideBox from "./components/RightSideBox/index";
import StyledBox from "./components/StyledBox/index";
import StyledPaper from "./components/StyledPaper/index";
import Wrapper from "./components/Wrapper/index";
import { LoginPage } from "../../types/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { setLoginLoading } from "../../reducers/auth";
import { RootState } from "../../reducers/rootReducer";
import { history } from "../Routes";

type Props = {
  data: LoginPage;
};

const Login: React.FC<Props> = ({ data }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoginLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated === true) {
      history.push("/");
    }
  }, [dispatch, isAuthenticated]);

  return (
    <StyledBox>
      <Logo withWrapper />
      <BackgroundElements />
      <StyledPaper>
        <LeftSideBox>
          <Wrapper data={data} />
        </LeftSideBox>
        <RightSideBox>
          <Blog />
        </RightSideBox>
      </StyledPaper>
    </StyledBox>
  );
};

export default Login;
