import React from "react";
import styled from "styled-components";
import { ButtonBase } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

const Button = styled(ButtonBase)`
  height: 61px;
  width: 32px;
  background-color: #F5F5F5;
  color: ${(props) => props.theme.palette.primary.main};
  box-shadow: -2px 1px 4px rgba(0,0,0,0.25);
  position: absolute;
  left: -32px;
`;

type Props = {
  onClick: () => void;
};

const FilterButton: React.FC<Props> = ({ onClick }) => (
  <Button onClick={onClick}>
    <FilterListIcon />
  </Button>
);

export default FilterButton;
