import React from "react";
import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

type Props = {
  size: "large" | "medium";
  [key: string]: any;
};

const StyledCheckbox = styled(ButtonBase)<Props>`
  box-shadow: 0px 4px 9px rgba(82, 95, 129, 0.35);
  border-radius: 30px;
  background:  ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.text.light};
  font-style: normal;
  font-weight: bold;
  text-align: center;

  &:hover {
    box-shadow: 0px 6px 11px rgba(82, 95, 129, 0.37);
  }

  &:active {
    box-shadow: inset 0px 4px 9px rgba(82, 95, 129, 0.55);
  }

  &:disabled {
    background: ${props => props.theme.palette.grey.light};
    box-shadow: none;
  }

  ${({ size }) =>
    size === "large" &&
    `
      width: 347px;
      height: 53px;
      font-size: 18px;
      line-height: 21px;
    `}

  ${({ size }) =>
    size === "medium" &&
    `
      width: 150px;
      height: 46px;
      font-size: 14px;
      line-height: 16px;
    `}
`;

const Button: React.FC<Props> = ({ children, ...props }) => (
  <StyledCheckbox disableRipple {...props}>
    {children}
  </StyledCheckbox>
);

export default Button;