// import randomColor from 'randomcolor'

// const randomColors = randomColor({hue: 'random',luminosity: 'random',count: 54});
const randomColors = [
    "#8e7847",
    "#a6d1a3",
    "#818c8a",
    "#c49183",
    "#cdb1f9",
    "#45227a",
    "#95ba12",
    "#123807",
    "#d1afa5",
    "#7a8e1d",
    "#1a331f",
    "#097721",
    "#9e5d84",
    "#ce5f14",
    "#8f09b5",
    "#7e8775",
    "#398441",
    "#9daef9",
    "#a4d3f9",
    "#796889",
    "#345b39",
    "#908b91",
    "#6ddb87",
    "#9b8d7f",
    "#8283b7",
    "#abb79d",
    "#331421",
    "#350117",
    "#697f73",
    "#bc4bf4",
    "#7f03a8",
    "#29243a",
    "#8e3b30",
    "#b29ba5",
    "#03054c",
    "#9ccc8a",
    "#09140f",
    "#1d111e",
    "#d585e5",
    "#14131c",
    "#6a0e91",
    "#2a7f49",
    "#441c29",
    "#281815",
    "#0e0638",
    "#378457",
    "#1e190a",
    "#6e9fa0",
    "#2059c1",
    "#1d071e",
    "#edf46b",
  ];

  const getRandomColor = (index: number): string => {
    const color = randomColors[index];
    return color;
  };

  export default getRandomColor;