import { Cell } from "../types/Table";
import { comparePrices } from "./comparePrices";

type RowFormatter = {
  products: any;
  datePrices: any;
  isFirst: boolean;
  isOdd: boolean;
  isLast: boolean;
  index: number;
};

const getProductNameColumn = ({
  products,
  isFirst,
  isOdd,
  isLast,
}: RowFormatter) => ({
  data: products[0]?.productName,
  meta: { isFirst, isOdd, isLast },
});

const getRetailerNameColumn = ({ products, isOdd }: RowFormatter) => {
  const url = products[0]?.url;
  const data = products[0]?.retailer;

  return {
    data,
    meta: { isOdd, url },
  };
};

const getPriceColumns = ({ datePrices, isOdd }: RowFormatter) => {
  const dateValues = Object.values(datePrices);
  const withIsOdd = dateValues.map(({ data, meta }: any) => ({
    data,
    meta: {
      ...meta,
      isOdd,
    },
  }));

  const reducedData = withIsOdd.reduce((acc: any[], current: any, index) => {
    const prev = acc[index - 1];
    const curr = current;

    return [
      ...acc,
      {
        ...current,
        meta: {
          ...current.meta,
          isHigher: comparePrices(prev, curr, "isHigher"),
          isLower: comparePrices(prev, curr, "isLower"),
        },
      },
    ];
  }, []);

  return reducedData;
};

const rowFormatter = (props: RowFormatter): Cell[] => {
  return [
    getProductNameColumn(props),
    getRetailerNameColumn(props),
    ...getPriceColumns(props),
  ];
};

export default rowFormatter;
