import { Paper } from "@material-ui/core";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  overflow: hidden !important;
  max-width: 60rem;
  width: 100%;
  min-width: calc(
    370px + ${(props) => props.theme.components.login.padding} * 2
  );
  z-index: 1000;
  background-color: ${(props) => props.theme.palette.grey.superLight};
  box-shadow: ${(props) => props.theme.components.login.boxShadow};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 1100px) {
    margin-top: 6.5rem;
    margin-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default StyledPaper;
