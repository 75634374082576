import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { setTimeframe } from "../../reducers/productAnalysis";
import DateRangePickerWrapper from "../DateRangePickerWrapper";

const StyledBox = styled(Box)`
  width: 400px;
  background-color: #ffffff;
  padding: 60px 30px;
  box-shadow: 0px 4px 9px rgba(82, 95, 129, 0.33);
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

type Props = {
  closeForm: () => void;
};

const Form: React.FC<Props> = ({ closeForm }) => {
  const [dateRange, setDateRange] = React.useState<{ startDate?: any; endDate?: any }>({});
  const dispatch = useDispatch();

  const handleGo = () => {
    closeForm();
    dispatch(
      setTimeframe({
          from: dateRange.startDate,
          to: dateRange.endDate,
      })
    );
  };

  return (
    <StyledBox>
      <form noValidate>
        <DateRangePickerWrapper setDateRange={setDateRange}/>
        <ButtonWrapper>
          <Button size="medium" onClick={() => handleGo()}>
            Go
          </Button>
        </ButtonWrapper>
      </form>
    </StyledBox>
  );
};

export default Form;
