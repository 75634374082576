import auth0 from "auth0-js";

const config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  scope: "openid email read:users_app_metadata",
  responseType: "token id_token",
  redirectUri: process.env.REACT_APP_CALLBACK_URL
};

const webAuth = new auth0.WebAuth(config);

export default webAuth;