import cubejsApi from "../api/index";
import getModelName from "../getModelName";

export const getClientRuns = async (
  accessToken: string,
  dateRange: string[]
) => {
  const Runs = getModelName("Runs");
  const resultSet = await cubejsApi(accessToken).load({
    dimensions: [`${Runs}.createdat`],
    timeDimensions: [
      {
        dimension: `${Runs}.createdat`,
        granularity: "day",
        dateRange,
      },
    ],
  });

  const tablePivot = resultSet.tablePivot();
  const createdAtValues = tablePivot.map(
    (i) => i[`${Runs}.createdat`]
  ) as string[];

  return createdAtValues;
};
