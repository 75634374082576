import { Cell } from "../../types/Table";
import _ from "lodash";

const addHourMeta = (arr: Cell[]) => {
  const grouped = _.groupBy(arr, "meta.day");

  const withMeta = Object.values(grouped)
    .map((group) => {
      return group.map((date, index) => {
        return {
          ...date,
          meta: {
            ...date.meta,
            isFirstDate: index === 0,
            isLastDate: index === group.length - 1,
          },
        };
      });
    })
    .flat();

  const sortedDates = _.sortBy(withMeta, 'meta.monthNumber')

  return sortedDates;
};

export default addHourMeta;
