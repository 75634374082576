import React from "react";
import { MultiGrid, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import styled from "styled-components";
import { connect } from "react-redux";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import { ReactComponent as Ban } from "./ban.svg";
import { setSelectedProduct } from "../../../../../reducers/productAnalysis";
let { AutoSizer } = require("react-virtualized");

if (process.env.NODE_ENV === "test") {
  AutoSizer = require("react-virtualized/dist/commonjs/AutoSizer");
}

const StyledIcons = styled.div`
  display: grid;
  grid-template-rows: 12px 12px;
  grid-template-columns: 9px;
  width: 10px;
  position: absolute;
  right: -16px;
  top: 44%;
  transform: translateY(-50%);
  align-items: center;

  svg {
    width: 9px;
    height: 9px;
  }
`;

const PriceCell = styled.div`
  position: relative;
  height: 100%;
  align-items: center;
`;

const StyledBan = styled(Ban)`
  color: #000000;
`;

const StyledLink = styled.a`
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const borderTop = {
  borderTop: "1px solid #BDBDBD",
};

const borderBottom = {
  borderBottom: "1px solid #BDBDBD",
};

const styleBottomLeftGrid = {
  backgroundColor: "#ffffff",
  boxShadow: "4px 2px 5px rgba(82, 95, 129, 0.1)",
  zIndex: "2",
};

const styleTopLeftGrid = {
  backgroundColor: "#ffffff",
  zIndex: 999,
  boxShadow: 'rgba(82, 95, 129, 0.1) 4px 2px 5px'
  // borderBottom: "1px solid #BDBDBD",
};

const styleTopRightGrid = {
  fontWeight: "bold",
};

const titleCellStyle = {
  fontSize: "14px",
  lineHeight: "16px",
};

const bold = {
  fontWeight: "bold",
};

const colored = {
  color: "#447eeb",
};

const dateCellStyle = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
};

const hiddenTitleStyle = {
  textIndent: "-999px",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const lastTitleCellStyle = {
  borderBottom: "1px solid #e0e0e0",
};

const oddCellStyle = {
  background: "#F6F7F8",
};

const higherCellStyle = {
  color: "#28A745",
  fontWeight: "bold",
};

const lowerCellStyle = {
  color: "#EB5757",
  fontWeight: "bold",
};

const leftGridColumnStyle = {
  padding: "0 10px",
  justifyContent: "flex-start",
  overflow: "hidden",
};

const cellStyle = {
  borderBottom: "1px solid #e0e0e0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  boxSizing: "border-box",
  background: "white",
  zIndex: 1,
  maxWidth: 210,
};

class MultiGridTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      fixedColumnCount: 2,
      fixedRowCount: 1,
      scrollToColumn: props.list[0].length,
      scrollToRow: 0,
      selectedProduct: "",
      hoverRowIndex: undefined,
      isHovered: false,
    };

    this._cache = new CellMeasurerCache({
      defaultHeight: 44,
      fixedHeight: true,
      minWidth: 120,
      minHeight: 44,
      keyMapper: (rowIndex, columnIndex) => (columnIndex === 0 ? rowIndex : 1),
    });

    this.cellRenderer = this.cellRenderer.bind(this);
    this.onFixedColumnCountChange = this.createEventHandler("fixedColumnCount");
    this.onFixedRowCountChange = this.createEventHandler("fixedRowCount");
    this.onScrollToColumnChange = this.createEventHandler("scrollToColumn");
    this.onScrollToRowChange = this.createEventHandler("scrollToRow");
  }

  getCellStyle = (columnIndex, rowIndex, cell) => {
    const isFirstCol = columnIndex === 0;
    const isProductRow = rowIndex > 0;
    const isRetailerColumn = columnIndex === 1;
    const isRetailerCell = isRetailerColumn && isProductRow;
    const isProductNameCell = isFirstCol && isProductRow;
    const isFirstRow = rowIndex === 0;
    const isFirst = cell.meta && cell.meta.isFirst;
    const isLast = cell.meta && cell.meta.isLast;
    const isOdd = cell.meta && cell.meta.isOdd;
    const isHigher = cell.meta && cell.meta.isHigher;
    const isLower = cell.meta && cell.meta.isLower;
    const isDate = cell.meta && cell.meta.month;
    const isLeftGrid = columnIndex === 0 || columnIndex === 1;
    const isNA = cell.meta && cell.meta.isNA;

    const style = (challenge, passed) => (challenge ? passed : {});

    return {
      ...style(true, cellStyle),
      ...style(isLeftGrid, leftGridColumnStyle),
      ...style(isFirstRow && isLeftGrid, { ...bold, ...titleCellStyle }),
      ...style(isFirstRow, borderBottom),
      ...style(isProductNameCell && isFirst, {
        ...colored,
        ...bold,
        ...titleCellStyle,
      }),
      ...style(isProductNameCell && !isFirst, hiddenTitleStyle),
      ...style(isRetailerCell, {
        ...titleCellStyle,
        textTransform: "capitalize",
        ...leftGridColumnStyle,
      }),
      ...style(isFirstCol, {
        paddingLeft: "30px",
      }),
      ...style(isOdd, oddCellStyle),
      ...style(isHigher, higherCellStyle),
      ...style(isLower, lowerCellStyle),
      ...style(isLast, lastTitleCellStyle),
      ...style(isDate, { ...dateCellStyle, ...bold }),
      ...style(isNA, { fontSize: "12px" }),
    };
  };

  monthRenderer({ meta, data }) {
    const [, , hour] = data.split(" ");

    return (
      <>
        <span
          style={{
            ...bold,
            ...titleCellStyle,
            ...(meta.isFirstDate || meta.isLastDate
              ? { visibility: "visible" }
              : { visibility: "hidden" }),
          }}
        >
          {meta.month} {meta.day}
        </span>
        <span style={{ fontWeight: 300 }}>{hour}</span>
      </>
    );
  }

  getCellValue(cell) {
    const isHigher = cell.meta && cell.meta.isHigher;
    const isLower = cell.meta && cell.meta.isLower;
    const isDate = cell.meta && cell.meta.month;
    const isProductLink = cell.meta && cell.meta.url;
    const isNotAvailable =
      Number.isInteger(Number(cell.data)) &&
      cell.meta &&
      cell.meta.available === false &&
      cell.meta.isPriceCell === true;
    const isNA = cell.meta && cell.meta.isNA === true;

    if (isDate) {
      return this.monthRenderer(cell);
    }

    if (isProductLink) {
      return (
        <StyledLink
          href={cell.meta.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cell.data}
        </StyledLink>
      );
    }

    let price = cell.data;

    if (isNA) {
      price = "N/A";
    }

    if (price === "0.00") {
      price = "—";
    }

    return (
      <PriceCell style={{ ...titleCellStyle, display: "flex" }}>
        {price}
        <StyledIcons>
          <div>
            {isHigher && <ArrowUp />}
            {isLower && <ArrowDown />}
          </div>
          <div>{isNotAvailable && <StyledBan />}</div>
        </StyledIcons>
      </PriceCell>
    );
  }

  setIsHovered(value, rowIndex) {
    this.setState({
      hoverRowIndex: rowIndex,
      isHovered: value,
    });
  }

  selectProduct(list, rowIndex) {
    this.props.dispatch(setSelectedProduct(list[rowIndex][0].data));
    this.props.handleOpenModal();
  }

  cellRenderer({ columnIndex, key, rowIndex, style, parent }) {
    const cell = this.props.list[rowIndex][columnIndex];
    const data = JSON.stringify(cell);

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        {({ registerChild }) => (
          <div
            style={{
              ...style,
              ...this.getCellStyle(columnIndex, rowIndex, cell),
              ...(this.state.isHovered &&
              this.state.hoverRowIndex === rowIndex &&
              rowIndex > 0
                ? { background: "#eeeeef" }
                : {}),
            }}
            ref={registerChild}
            data={data}
            onMouseEnter={() => this.setIsHovered(true, rowIndex)}
            onMouseLeave={() => this.setIsHovered(false, rowIndex)}
            onClick={() => {
              if (columnIndex !== 1 && rowIndex !== 0) {
                this.selectProduct(this.props.list, rowIndex);
              }
            }}
          >
            {this.getCellValue(cell)}
          </div>
        )}
      </CellMeasurer>
    );
  }

  createEventHandler(property) {
    return (event) => {
      const value = parseInt(event.target.value, 10) || 0;

      this.setState({
        [property]: value,
      });
    };
  }

  render() {
    return (
      <>
        {/* Selected product: {this.state.selectedProduct} */}
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              {...this.state}
              {...this.props}
              hideTopRightGridScrollbar={true}
              hideBottomLeftGridScrollbar={true}
              enableFixedColumnScroll={true}
              enableFixedRowScroll={false}
              cellRenderer={this.cellRenderer}
              columnCount={this.props.list[0].length}
              height={height}
              rowHeight={this._cache.rowHeight}
              rowCount={this.props.list.length}
              style={borderTop}
              styleBottomLeftGrid={styleBottomLeftGrid}
              styleTopLeftGrid={styleTopLeftGrid}
              styleTopRightGrid={styleTopRightGrid}
              width={width}
              columnWidth={this._cache.columnWidth}
              deferredMeasurementCache={this._cache}
              onScroll={() => this.setIsHovered(false)}
            />
          )}
        </AutoSizer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProduct: (product) => dispatch(setSelectedProduct(product)),
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(MultiGridTable);
