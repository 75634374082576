import React from "react";
import styled from "styled-components";
import { Box, CircularProgress } from "@material-ui/core";

import ActionsBar from "./ActionsBar";
import ButtonComponent from "../../../Button/index";
import { useSelector, useDispatch } from "react-redux";
import Fields from "./components/Fields";
import { LoginPage } from "../../../../types/LoginPage";
import { RootState } from "../../../../reducers/rootReducer";

const StyledBox = styled(Box)`
  min-width: 270px;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledForm = styled.form`
  width: auto;
  max-width: 370px;
  margin: auto;

  > div:not(:first-child) {
    margin-top: 20px;
  }
`;

const StyledButton = styled(ButtonComponent)`
  margin-top: 35px;
`;

const StyledInputWrapper = styled.div`
`;

const LoginForm: React.FC<LoginPage> = ({ actions, button, form, action }) => {
  const dispatch = useDispatch();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (action) {
      action(dispatch);
    }
  };

  const state = useSelector((state: RootState) => state);

  return (
    <StyledBox>
      <StyledForm onSubmit={handleSubmit} autoComplete="off">
        {form &&
          form.map((field, index) => {
            const Field = Fields[field];
            const Component = Field.component;

            return Component ? (
              <StyledInputWrapper key={index}>
                {
                  <Component
                    value={Field.getValue(state)}
                    onChange={(value: any) => Field.setValue(dispatch, value)}
                    isValid={(value: any) => Field.isValid(value)}
                    id={Field.key}
                    label={Field.name}
                    type={Field.type}
                  />
                }
              </StyledInputWrapper>
            ) : null;
          })}
        {actions && <ActionsBar />}
        {form && (
          <Box display="flex" justifyContent="center">
            <StyledButton
              size="large"
              type="submit"
              disabled={state.auth.loginLoading}
            >
              {state.auth.loginLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                button
              )}
            </StyledButton>
          </Box>
        )}
      </StyledForm>
    </StyledBox>
  );
};

export default LoginForm;
