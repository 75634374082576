import { createSlice } from "@reduxjs/toolkit";
import { TableView, TableTimeframe, Cell } from "../types/Table";
import { AppThunk } from "../store";
import moment from "moment";
import _ from "lodash";
import { getClientRuns } from "../utils/api/runs";
import addHourMeta from "../utils/dates/addHourMeta";
import formatDay from "../utils/dates/formatDay";
import formatHour from "../utils/dates/formatHour";
import { getClientVariants } from "../utils/api/records";
import { getProduct } from "../utils/api/product";
import rollbar from "../utils/rollbar";

const format = "YYYY-MM-DDTHH:mm";

const getToDate = (date: Date = new Date()) => {
  const mDate = moment(date).utc();
  const isCurrentDate = mDate.isSame(moment.utc(), "day");

  if(isCurrentDate) {
    const now = moment.utc()
    const remainder = 30 + (now.minute() % 30);

    return now.subtract(remainder, "minutes").format(format);
  }

  return mDate.tz("Europe/Warsaw").endOf("day").utc().format(format);
};

const getFromDate = (date: Date = new Date()) => {
  const from = moment(date).utc();

  return from.tz("Europe/Warsaw").startOf("day").utc().format(format);
};

type CurrentDisplayState = {
  view: TableView;
  timeframe: TableTimeframe;
  tableHead: Cell[];
  variants: any;
  chartVariants: any;
  clientRuns: string[];
  isTableLoading: boolean;
  isRunsLoading: boolean;
  isChartLoading: boolean;
  selectedProduct: string;
};

const baseTableHeadColumns = [{ data: "Product" }, { data: "Retailer" }];

let initialState: CurrentDisplayState = {
  view: "Hours",
  timeframe: {
    from: "",
    to: "",
  },
  tableHead: baseTableHeadColumns,
  chartVariants: [],
  variants: [],
  isTableLoading: false,
  isRunsLoading: true,
  isChartLoading: true,
  clientRuns: [],
  selectedProduct: "",
};

const productAnalysisSlice = createSlice({
  name: "productAnalysis",
  initialState,
  reducers: {
    setCurrentView(state, action) {
      state.view = action.payload;
    },
    setInitialTimeframe(state) {
      state.timeframe = {
        from: moment().utc().subtract(2, "days").format(format),
        to: getToDate(),
      };
    },
    setTimeframe(state, action) {
      const { from ,to } = action.payload;

      state.timeframe = {
        from: getFromDate(from),
        to: getToDate(to),
      };

      state.variants = []
    },
    setRunsLoading(state, action) {
      state.isRunsLoading = action.payload;
    },
    setClientRuns(state, action) {
      state.clientRuns = action.payload;
    },
    setTableLoading(state, action) {
      state.isTableLoading = action.payload;
      if(action.payload === true) {
        state.variants = [];
      }
    },
    setTableHead(state, action) {
      state.tableHead = [...baseTableHeadColumns, ...action.payload];
    },
    setTable(state, action) {
      state.variants.push(...action.payload);
      console.log(state.variants.length);
    },
    setChart(state, action) {
      state.chartVariants = action.payload;
    },
    setChartLoading(state, action) {
      state.isChartLoading = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
  },
});

export const {
  setChart,
  setChartLoading,
  setClientRuns,
  setCurrentView,
  setInitialTimeframe,
  setTimeframe,
  setRunsLoading,
  setSelectedProduct,
  setTable,
  setTableHead,
  setTableLoading,
} = productAnalysisSlice.actions;

export default productAnalysisSlice.reducer;

export const setView = (): AppThunk => async (dispatch, getState) => {
  let dates: Cell[] = [];
  const { view, clientRuns } = getState().productAnalysis;

  switch (view) {
    case "Days":
      dates = _.uniqBy(clientRuns.map(formatDay), "data");
      break;

    case "Hours":
      dates = addHourMeta(clientRuns.map(formatHour));
      break;
  }

  dispatch(setTableHead(dates));
};

export const getRuns = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setRunsLoading(true));
    const { timeframe } = getState().productAnalysis;
    const { accessToken } = getState().auth;
    const dateRange = Object.values(timeframe);
    const clientRuns = await getClientRuns(accessToken, dateRange);
    dispatch(setClientRuns(clientRuns));
    dispatch(setView());
    dispatch(getRecords());
    dispatch(setRunsLoading(false));
  } catch (err) {
    console.log(err, "err");
    dispatch(setRunsLoading(false));
  }
};

export const getRecords = (limit: number = 0): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(setTableLoading(true));
  const {
    productAnalysis: { timeframe },
    auth: { accessToken },
  } = getState();
  const { from, to } = timeframe;
  const newTo = moment(to, format)
    .add(30, "minutes")
    .format(format);
  const dateRange = [from, newTo];

  let offset = Number(process.env.REACT_APP_CUBE_JS_LIMIT) || 5000;
  let currentOffset = 0;
  let shouldFetch = true;

  if (from && to) {
    do {
      try {
        const clientVariants = await getClientVariants(
          accessToken,
          dateRange,
          currentOffset,
          offset
        );

        if (clientVariants.length > 0) {
          dispatch(setTable(clientVariants));
          currentOffset += offset;
        } else {
          shouldFetch = false;
        }
      } catch (err) {
        rollbar.log(err);
        shouldFetch = false;
      }
    } while (shouldFetch === true);
  }

  dispatch(setTableLoading(false));
};

export const getChartRecords = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setChartLoading(true));
    const {
      productAnalysis: { timeframe, selectedProduct },
      auth: { accessToken },
    } = getState();
    const dateRange = Object.values(timeframe);
    const { from, to } = timeframe;

    if (from && to) {
      const clientVariants = await getProduct(
        accessToken,
        dateRange,
        selectedProduct
      );
      dispatch(setChart(clientVariants));
    }
  } catch (err) {
    console.log(err, "err");
  }

  dispatch(setChartLoading(false));
};
