import ButtonComponent from "../../../Button/index";
import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const BackToLogin: React.FC = () => (
  <Box display="flex" justifyContent="center">
    <Link to="/login">
      <ButtonComponent size="large">Back to log in</ButtonComponent>
    </Link>
  </Box>
);

export default BackToLogin;
