import getRandomColor from "../colors/getRandomColor";
import getColor from "../colors/getColor";
import getModelName from "../getModelName";
import cubejsApi from "./index";

export const getProduct = async (
  accessToken: string,
  dateRange: string[],
  productName: string
) => {
  const Variants = getModelName("Variants");

  const resultSet = await cubejsApi(accessToken).load({
    dimensions: [
      `${Variants}.productname`,
      `${Variants}.retailername`,
      `${Variants}.rundate`,
    ],
    filters: [
      {
        dimension: `${Variants}.productname`,
        operator: "equals",
        values: [productName],
      },
    ],
    order: {
      [`${Variants}.rundate`]: "asc",
      [`${Variants}.retailername`]: "asc",
      [`${Variants}.productname`]: "asc",
    },
    measures: [`${Variants}.minPrice`],
    timeDimensions: [
      {
        dimension: `${Variants}.rundate`,
        dateRange,
      },
    ],
  });

  const series = resultSet.series({
    x: [`${Variants}.rundate`],
    y: [`${Variants}.retailername`, `${Variants}.minPrice`],
  });

  const xAxis = {
    type: "category",
    data: series[0].series.map((s) => s.category.split(",")[0]),
  };

  const color: string[] = [];

  const formatedSeries = series.map((series, index) => {
    const name = series.key.split(",")[0];
    color.push(getColor(name) || getRandomColor(index));

    return {
      name,
      type: "line",
      step: "end",
      data: series.series.map((series) => series.value),
      lineStyle: {
        width: 4,
      },
      symbolSize: 8,
      showAllSymbol: false,
      connectNulls: false,
    };
  });

  return {
    xAxis,
    series: formatedSeries,
    legend: {
      // icon: 'none',
      data: series.map((series) => {
        const name = series.key.split(",")[0];
        return {
          name,
        };
      }),
    },
    colors: color.filter(Boolean),
  };
};
