import { Box } from "@material-ui/core";
import styled from "styled-components";

const LeftSideBox = styled(Box)`
  overflow: hidden !important;
  min-width: 370px;
  width: 100%;
  max-height: 55rem;
  max-width: 30vw;
  padding: ${(props) => props.theme.components.login.padding};
  background: ${(props) => props.theme.components.login.leftSideBox.background};
  box-shadow: ${(props) => props.theme.components.login.boxShadow};
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 100%;
  flex: 1;

  @media (max-width: 1320px) {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: 900px) {
    border-radius: 5px 5px 0 0;
  }
`;

export default LeftSideBox;
