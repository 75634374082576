const shouldOmitPrice = (prev: any, current: any): boolean => {
  const notAllowed = ["0,00", "0.00", null, undefined];

  if(prev?.meta?.isNA || current?.meta?.isNA) {
      return true;
  }

  return notAllowed.includes(prev?.data) || notAllowed.includes(current?.data);
};

export const comparePrices = (
  prev: any,
  current: any,
  diff: "isHigher" | "isLower"
) => {
  let result = false;

  if (shouldOmitPrice(prev, current)) {
    return result;
  }

  if (diff === "isHigher") {
    result = Number(prev?.data) < Number(current?.data);
  } else if (diff === "isLower") {
    result = Number(prev?.data) > Number(current?.data);
  }

  return result;
};
