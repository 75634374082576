import BackToLogin from "../ResetPassword/BackToLogin";
import Footer from "./Footer";
import Form from "../Form";
import Header from "./Header";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { LoginPage } from "../../../../types/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import { setResetPasswordConfirm } from "../../../../reducers/auth";

type Props = {
  data: LoginPage;
};

const StyledBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
`;

const emailSent = {
  header: {
    bigText: "",
    smallText: `We have sent an email with instructions to`,
  },
  backToLogin: true,
  justifyContent: "center",
  button: '',
  action: '',
  form: undefined,
  actions: false,
  footer: false,
};

const Wrapper: React.FC<Props> = ({ data }) => {
  const [staticData, setStaticData] = useState<LoginPage>(data);
  const { resetPasswordConfirm, email } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    setStaticData(data);
  }, [data]);

  useEffect(() => {
    if(resetPasswordConfirm === true) {
      dispatch(setResetPasswordConfirm(false))
      setStaticData(emailSent);
    }
  }, [dispatch, resetPasswordConfirm]);

  const {
    backToLogin,
    footer,
    header,
    justifyContent,
  } = staticData;

  return (
    <StyledBox justifyContent={justifyContent}>
      <Box>
        <Header
          bigTextChildren={header.bigText}
          smallTextChildren={`${header.smallText} ${backToLogin ? email : ''}`}
        />
        <Form {...staticData} />
        {backToLogin && <BackToLogin />}
      </Box>
      {footer && <Footer />}
    </StyledBox>
  );
};

export default Wrapper;
