import priceFinder from "../../utils/priceFinder";
import rowFormatter from "../../utils/rowFormatter";
import nest from "../../utils/nest";
import { Cell, DataItem, Row } from "../../types/Table";
import isFirst from "../utils/isFirst";
import isOdd from "../utils/isOdd";
import isLast from "../utils/isLast";

const groupData = (data: DataItem[]) => nest(data, ["productName", "retailer"]);

const process = (data: DataItem[], dates: Cell[]): Row[] => {
  const groupedData = groupData(data);

  const result: Row[] = [];

  const productEntries = Object.entries(groupedData);

  productEntries.forEach(([, products], productIndex) => {
    const retailerEntries: [string, DataItem[]][] = Object.entries(products);

    retailerEntries.forEach(([, retailerProducts], retailerIndex) => {
      const pricesFound = priceFinder(retailerProducts, dates);
      const row = rowFormatter({
        products: retailerProducts,
        datePrices: pricesFound,
        isFirst: isFirst(retailerIndex),
        isOdd: isOdd(productIndex),
        isLast: isLast(retailerIndex, retailerEntries),
        index: retailerIndex,
      });

      result.push(row);
    });
  });

  return result;
};

export default process;
