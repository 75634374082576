import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import webAuth from "../utils/auth0";
import { history } from "../components/Routes/index";

export type AuthState = {
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
  idToken: string;
  accessToken: string;
  isAuthenticated: boolean;
  error: string;
  loginLoading: boolean;
  resetPasswordConfirm: boolean;
  reportPath: string;
};

const initialState: AuthState = {
  email: "",
  password: "",
  newPassword: "",
  confirmPassword: "",
  idToken: "", // Auth0
  accessToken: "", // AuthAPI
  isAuthenticated: false,
  loginLoading: false,
  error: "",
  resetPasswordConfirm: false,
  reportPath: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setNewPassword(state, action) {
      state.newPassword = action.payload;
    },
    setConfirmPassword(state, action) {
      state.confirmPassword = action.payload;
    },
    setIdToken(state, action) {
      state.idToken = action.payload;
      state.email = "";
      state.password = "";
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
      state.isAuthenticated = true;
    },
    setReportPath(state, action) {
      state.reportPath = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setLoginLoading(state, action) {
      state.loginLoading = action.payload;

      if (action.payload === true) {
        state.error = "";
      }
    },
    setResetPasswordConfirm(state, action) {
      state.resetPasswordConfirm = action.payload;
    },
    setLogout(state) {
      state.isAuthenticated = false;
      state.idToken = "";
      state.accessToken = "";
      state.reportPath = "";
    },
  },
});

export const {
  setEmail,
  setPassword,
  setIdToken,
  setAccessToken,
  setError,
  setLoginLoading,
  setResetPasswordConfirm,
  setLogout,
  setReportPath,
  setNewPassword,
  setConfirmPassword,
} = authSlice.actions;

export default authSlice.reducer;

export const login = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoginLoading(true));
    webAuth.login(
      {
        username: getState().auth.email,
        password: getState().auth.password,
        realm: "Username-Password-Authentication",
      },
      (...data) => {
        if (data) {
          const [response] = data;
          const { error_description } = response || {};

          if (error_description) {
            dispatch(setLoginLoading(false));
            dispatch(setError(error_description));
          }
        }
      }
    );
  } catch (err) {
    dispatch(setLoginLoading(false));
    console.log(err);
  }
};

export const logout = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLogout());
  webAuth.logout({
    returnTo: window.location.origin + "/login",
  });
};

export const resetPassword = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoginLoading(true));
  setTimeout(() => {
    dispatch(setResetPasswordConfirm(true));
    dispatch(setLoginLoading(false));
  }, 3000);
};

export const getCubeJsToken = (): AppThunk => async (dispatch, getState) => {
  const { REACT_APP_AUTH_ENDPOINT } = process.env;

  if (REACT_APP_AUTH_ENDPOINT) {
    fetch(REACT_APP_AUTH_ENDPOINT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getState().auth.idToken}`,
      },
    })
      .then((response) => response.json())
      .then(({ accessToken, reportPath }) => {
        dispatch(setAccessToken(accessToken));

        if (reportPath) {
          dispatch(setReportPath(reportPath));
        }

        history.push("/");
        dispatch(setLoginLoading(false));
      })
      .catch((e) => {
        console.log("error", e);
        dispatch(setLoginLoading(false));
      });
  }
};
