import React from "react";
import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";
import * as Icons from "react-icons/md";

type Props = {
  icon?: keyof typeof Icons;
  [key: string]: any;
};

const StyledButton = styled(ButtonBase)<Props>`
  color: ${props => props.theme.palette.primary.main};
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: bold;
  padding: 0;

  svg {
    padding-right: 6px;
    font-size: 20px;
  }

  &:active {
    color: ${props => props.theme.palette.text.dark};
  }

  &:disabled {
    color: ${props => props.theme.palette.grey.light};
  }
`;

const TextButton: React.FC<Props> = ({ children, icon, ...props }) => {
  const IconTag = icon ? Icons[icon] : null;
  return (
    <StyledButton disableRipple {...props}>
      {IconTag && <IconTag/>} {children}
    </StyledButton>
  );
};

export default TextButton;
