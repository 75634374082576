import numeral from "numeral";

numeral.register("locale", "pl", {
  delimiters: {
    thousands: "",
    decimal: ",",
  },
  abbreviations: {
    thousand: "tys.",
    million: "mln",
    billion: "mld",
    trillion: "bln",
  },
  ordinal: () => ".",
  currency: {
    symbol: "PLN",
  },
});

const formatPrice = (price: string | number, format = "0.00", locale = "pl") => {
  numeral.locale(locale);
  return numeral(price).format(format);
};


export default  formatPrice;