import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "../Logo/index";
import { ReactComponent as SignOut } from "./sign-out.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../reducers/auth";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: relative;
  box-sizing: border-box;
  padding: initial !important;
`;

const Navigation = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 30px 0 50px;
`;

const StyledUl = styled.ul`
  display: flex;

  li:not(:last-child) {
    margin-right: 2.81rem;
  }
`;

const StyledLi = styled.li`
  display: inline-flex;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    display: inline-flex;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    color: #286dcb;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #000000;
  font-family: Karla;
  font-style: normal;
  font-size: 16px;
`;

type Link = {
  path: string;
  text: string;
};

const nav: Link[] = [
  // {
  //   path: "/",
  //   text: "Product analysis",
  // },
  // {
  //   path: "/notifications",
  //   text: "My notification",
  // },
];

const Headline: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <StyledBox>
      <Navigation>
        <NavLink to="/">
          <Logo />
        </NavLink>
        <StyledUl>
          {nav.map((link, index: number) => (
            <StyledLi key={index}>
              <StyledLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "#447EEB",
                }}
                to={link.path}
                exact={true}
              >
                <span>{link.text}</span>
              </StyledLink>
            </StyledLi>
          ))}
          <StyledLi onClick={() => dispatch(logout())}>
            <SignOut />
            <span>Log out</span>
          </StyledLi>
        </StyledUl>
      </Navigation>
    </StyledBox>
  );
};

export default Headline;
