import { Cell, DataItem } from "../types/Table";

const priceFinder = (products: DataItem[], dates: Cell[]): Cell[] => {
  const result = dates.map((date: Cell) => {
    const dateProducts = products.filter((p) => p.formattedDate.includes(String(date.data)));
    const dateProduct = dateProducts[0];

    if (dateProducts.length > 1 && process.env.NODE_ENV === 'development') {
      console.log(dateProducts,'duplicated product fetched');
    }

    return {
      data: dateProduct ? dateProduct.price : 0,
      meta: {
        available: dateProduct ? dateProduct.available : null,
        isNA: !dateProduct,
        isPriceCell: Boolean(dateProduct),
        price: dateProduct?.price,
      },
    };
  });

  return result;
};

export default priceFinder;
