import React from "react";
import ReactEcharts from "echarts-for-react";
import formatPrice from "../../../../../utils/formatPrice";
import { getTimezoneDate } from "../../../../../utils/addParsedDate";
import { EChartOption } from "echarts";
import { FullWidthLinearLoader } from "../../../../LinearLoader";
import numeral from "numeral";
// import moment from "moment";

type Props = {
  xAxis: EChartOption.XAxis;
  series: EChartOption.SeriesLine[];
  legend: EChartOption.Legend;
  isChartLoading: boolean;
  colors: string[];
};

const Chart: React.FC<Props> = ({ series, xAxis, legend, isChartLoading, colors }) =>
  isChartLoading === false ? (
    <ReactEcharts
      option={{
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              formatter: function (params: any) {
                return getTimezoneDate(params.value).format("DD/MM/YYYY HH:mm");
              },
            },
          },
        },
        legend,
        grid: {
          left: "2%",
          right: "2%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: {
          ...xAxis,
          axisLabel: {
            color: "#4F4F4F",
            formatter: function (value: any, index: number) {
              const [date, time] = getTimezoneDate(value)
                .format("DD/MM/YYYY HH:mm")
                .split(" ");
              return `${
                time === "12:00" || time === "00:00" ? date + " " : ""
              }{time|${time}}`;
            },
            rotate: 45,
            rich: {
              time: {
                fontWeight: "bold",
              },
            },
            // interval: (index:number, value: string) => {
            //     const hour = getTimezoneDate(value).get("hour");
            //     const minutes = getTimezoneDate(value).get("minute");
            //     if((hour === 0 || hour === 12) && minutes === 0) {
            //         console.log(hour,'moment(value).get("hour")')
            //         return true;
            //     } else {
            //         return index % 2 === 0;
            //     }
            // }
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: ["10%", "10%"],
          min: function ({ min }) {
            if (min === 0) {
              return 0;
            }

            let lowerMin;

            if (min <= 99.99) {
              lowerMin = Math.round((min * 0.9) / 1) * 1;
            } else if (min <= 999.99) {
              lowerMin = Math.round((min * 0.9) / 10) * 10;
            } else {
              lowerMin = Math.round((min * 0.9) / 100) * 100;
            }

            const roundMin = Number(numeral(lowerMin).format("0,0"));

            if (roundMin > 0) {
              return roundMin;
            }

            return 0;
          },
          axisLabel: {
            color: "#4F4F4F",
            formatter: function (value: any, index: number) {
              if (value < 0) return "";
              return formatPrice(value);
            },
          },
          splitLine: {
            lineStyle: {
              color: "#E0E0E0",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 0,
            },
          },
        },
        color: colors,
        series,
      }}
    />
  ) : (
    <FullWidthLinearLoader width={250} text="Building your chart" />
  );

export default Chart;
