import hoursProcessing from "./hours/index";
import daysProcessing from "./day/index";
import processData from "./processData.ts";

const processing = {
  days: daysProcessing,
  hours: hoursProcessing,
};

const getList = (resultSet, { view, tableHead }) => {
  const proccessingKey = view.toLowerCase();
  const [, , ...datesRow] = tableHead;

  const result = [
    tableHead,
    ...processing[proccessingKey](processData(resultSet), datesRow),
  ];

  return result;
};

export { getList };
