import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { LoginPage } from "../../types/LoginPage";
import { AppDispatch } from "../../store";
import { login, resetPassword } from "../../reducers/auth";
import Login from "../../components/Login/index";
import ProductAnalysis from "../SPS/ProductAnalysis";
import PrivateRoute from "./components/PrivateRoute";
import { createBrowserHistory } from "history";

const loginPage: LoginPage = {
  header: {
    bigText: "Welcome",
    smallText: "Log in to continue",
  },
  form: ["Email", "Password"],
  button: "Log in",
  footer: true,
  actions: false,
  action: (dispatch: AppDispatch) => dispatch(login()),
};

const resetPasswordPage: LoginPage = {
  header: {
    bigText: "Enter email",
    smallText: "We will send you a link with password recovery instructions",
  },
  button: "Send",
  form: ["Email"],
  actions: false,
  footer: false,
  action: (dispatch: AppDispatch) => dispatch(resetPassword()),
};

const newPassword: LoginPage = {
  header: {
    bigText: "Set new password",
    smallText: "Enter a new password for your account",
  },
  form: ["NewPassword", "ConfirmPassword"],
  button: "Change password",
  actions: false,
  footer: false,
  action: null,
};

export const history = createBrowserHistory();

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login">
        <Login data={loginPage} />
      </Route>
      <Route path="/password-reset">
        <Login data={resetPasswordPage} />
      </Route>
      <Route path="/new-password">
        <Login data={newPassword} />
      </Route>
      <PrivateRoute path="/" component={ProductAnalysis} />
    </Switch>
  </Router>
);

export default Routes;
