import cubejs from "@cubejs-client/core";

const { REACT_APP_CUBE_JS_API } = process.env;

const cubejsApi = (accessToken?: string) => {
  return cubejs(accessToken || "", {
    apiUrl: REACT_APP_CUBE_JS_API || "",
  });
};

export default cubejsApi;
