import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Chart from "./components/Chart";
import Filters from "../../Filters";
import Header from "../../Header";
import ProductModal from "./components/Modal";
import TextButton from "../../TextButton";
import TextSwitch from "../../TextSwitch";
import Wrapper from "./components/Table/components/Wrapper/index";
import { Container, Box } from "@material-ui/core";
import { RootState } from "../../../reducers/rootReducer";
import { TableView } from "../../../types/Table";
import {
  getChartRecords,
  getRuns,
  setCurrentView,
  setInitialTimeframe,
  setView,
} from "../../../reducers/productAnalysis";
import { useSelector, useDispatch } from "react-redux";

const StyledBox = styled(Box)`
  padding: 25px 30px 16px 30px;
  display: flex;
  justify-content: space-between;
  height: 61px;

  a {
    color: inherit;
  }
`;

const SyledContainer = styled(Container)`
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTextButton = styled(TextButton)<{ $withFilters: boolean }>`
  ${({ $withFilters }) =>
    $withFilters &&
    css`
      margin-right: 26px;
    `}
`;

const ProductAnalysis: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const withFilters = process.env.REACT_APP_SHOW_FILTERS === 'true';

  const {
    chartVariants,
    isChartLoading,
    selectedProduct,
    timeframe,
    view,
  } = useSelector((state: RootState) => state.productAnalysis);
  const { accessToken, reportPath } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    dispatch(setInitialTimeframe());
  }, [dispatch]);

  useEffect(() => {
    if (accessToken && timeframe.from && timeframe.to) {
      dispatch(getRuns());
    }
  }, [dispatch, timeframe, accessToken]);

  const handleOnChange = (view: TableView) => {
    dispatch(setCurrentView(view));
    dispatch(setView());
  };

  const handleOpen = () => {
    setOpen(true);
    dispatch(getChartRecords());
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header />
      <SyledContainer disableGutters maxWidth={false}>
        {withFilters && <Filters />}
        <StyledBox>
          <TextSwitch
            on="Days"
            off="Hours"
            text="Table view"
            handleOnChange={handleOnChange}
            view={view}
          />
          {reportPath && (
            <StyledTextButton
              icon={"MdArrowDownward"}
              $withFilters={withFilters}
            >
              <a href={reportPath} target="_blank" rel="noopener noreferrer">
                Download CSV
              </a>
            </StyledTextButton>
          )}
        </StyledBox>
        <Wrapper handleOpenModal={handleOpen} />
      </SyledContainer>
      <ProductModal
        open={open}
        handleClose={handleClose}
        product={selectedProduct}
      >
        <Chart
          xAxis={chartVariants.xAxis}
          series={chartVariants.series}
          legend={chartVariants.legend}
          colors={chartVariants.colors}
          isChartLoading={isChartLoading}
        ></Chart>
      </ProductModal>
    </>
  );
};

export default ProductAnalysis;
