import React from "react";
import styled from "styled-components";
import { Typography, Box } from "@material-ui/core";
import TextButtonComponent from "../../../TextButton/index";

const StyledBox = styled(Box)`
  color: ${(props) => props.theme.palette.grey.dark};
  margin-top: 1rem;
  margin-bottom: -1rem;
  @media (max-width: 1250px) {
    margin-top: 2rem;
  }
`;

const ContactUsText = styled(Typography)`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: block;

  a {
    text-decoration: none;
  }
`;

const ContactUsTextButton = styled(TextButtonComponent)`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  align-item: initial;
  vertical-align: initial;
`;

const TermsAndPolicyText = styled(Typography)`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  margin-top: 3rem;
  @media (max-width: 1250px) {
    max-width: 70%;
    margin: 2rem auto 0 auto;
  }
`;

const TermsAndPolicyTextButton = styled(TextButtonComponent)`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  align-item: initial;
  vertical-align: initial;
`;

const Header: React.FC = () => {
  return (
    <StyledBox>
      <ContactUsText variant="body1">
        Don’t have an account?{" "}
        <a href="https://modvise.com/" target="_blank" rel="noopener noreferrer">
          <ContactUsTextButton>Contact us</ContactUsTextButton>
        </a>
      </ContactUsText>
      <TermsAndPolicyText variant="body1">
        By logging in, you agree to the{" "}
        <TermsAndPolicyTextButton>Terms of Service</TermsAndPolicyTextButton>{" "}
        and <TermsAndPolicyTextButton> Privacy Policy</TermsAndPolicyTextButton>
      </TermsAndPolicyText>
    </StyledBox>
  );
};

export default Header;
